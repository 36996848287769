@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Gilroy:wght@400;700&display=swap');

*{
    -ms-overflow-style: none;
}

::-webkit-scrollbar {
    display: none;
}

body {
    background-color: #f4f4f5;
}